import { useMetadata } from "hooks/useMetadata";
import dynamic from "next/dynamic";
import { strapiService, teamTailorService } from "@services";
import { Job } from "services/teamTailor/types";
import { LatestCaseStudy, getPageTranslations, throttleStaticProps } from "utils/server";
import { YuLifeLocale } from "@hooks";
import { StaticRoutes } from "@routes";
import { isLocaleInStaticRoute } from "@utils";
import { Meta } from "components/atoms/Meta";

const Careers = dynamic(() => import("../../components/pages/Careers/Careers"));

interface Props {
  jobs: Job[];
  latestCaseStudy: LatestCaseStudy;
  locale: YuLifeLocale;
}

const CareersComponent: React.FC<Props> = ({ jobs, latestCaseStudy, locale }) => {
  const metadata = useMetadata("careers");

  return (
    <>
      <Meta locale={locale} {...metadata} />
      <Careers jobs={jobs} latestCaseStudy={latestCaseStudy} />
    </>
  );
};

export const getStaticProps = async (context) => {
  const locale = context.locale as YuLifeLocale;

  if (!isLocaleInStaticRoute(locale, StaticRoutes.careers)) {
    return {
      notFound: true,
    };
  }

  const jobs = (await teamTailorService.getJobs()) || [];

  const jobsWithDepartments = await Promise.all(
    jobs.map(async (job) => {
      const department = await teamTailorService.getDepartment(job.id);
      return {
        ...job,
        department: department.attributes.name,
      };
    }),
  );

  const genericProps = await strapiService.getGenericProps(locale, {
    hasKeyResources: false,
    hasLatestCaseStudy: true,
  });

  return throttleStaticProps(
    {
      props: {
        jobs: jobsWithDepartments,
        err: null,
        messages: await getPageTranslations(locale),
        ...genericProps,
      },
      revalidate: 300,
    },
    5000,
    true,
  );
};

export default CareersComponent;
